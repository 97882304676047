<template>
  <div class="form-group csn-user-profile-button-wrapper">
    <button
      class="btn btn-primary mb-50 csn-user-profile-button"
      type="submit"
      @click="handleClick"
      :disabled="isSubmitting"
    >
      <ButtonLoader v-if="isSubmitting" />
      <span v-else>
        <i class="fa fa-btn fa-user"></i>
        {{ t('bttn_label') }}
      </span>
    </button>
  </div>
</template>
<script>
import { UPDATE_PROFILE_BUTTON, EventType } from '@/constants'

export default {
  name: UPDATE_PROFILE_BUTTON,
  components: {
    ButtonLoader: () => import('@/components/ButtonLoader'),
  },
  props: {
    isSubmitting: Boolean,
  },
  computed: {
    t() {
      return this.$createComponentTranslator(UPDATE_PROFILE_BUTTON)
    },
  },
  methods: {
    handleClick() {
      this.$emit(EventType.CLICK)
    },
  },
}
</script>
